import React, { CSSProperties, PropsWithChildren, createRef, useEffect, useState } from 'react';
import styles from './Modal.module.css';
import { logInfo, logMajorComponentRender } from '../../classes/Logger';
import { createPortal } from 'react-dom';

interface IModalProps extends PropsWithChildren {
    align?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    style?: CSSProperties,
    onClose: () => void,
    debugName?: string;
}

export const Modal: React.FC<IModalProps> = (props) => {

    logMajorComponentRender(Modal.name);

    const modalRef = createRef<HTMLDivElement>();
    const portalRef = createRef<HTMLDivElement>();
    const parentRect = modalRef.current?.parentElement?.getBoundingClientRect() ?? { top: -999999, left: 0, width: 0, height: 0 }
    const [top, SetTop] = useState(parentRect.top ?? -999999);
    const [left, SetLeft] = useState(parentRect.left ?? 0);
    const [width, SetWidth] = useState(parentRect.width ?? 0);
    const [height, SetHeight] = useState(parentRect.height ?? 0);
    const { align, onClose, style, children, debugName } = props;

    useEffect(() => {
        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key !== 'Escape') { return; }
            onClose();
        }

        const handleOutsideClick = (e: MouseEvent) => {

            if (portalRef?.current?.contains(e.target as Element)) {
                logInfo(`Clicked within ${debugName ?? '[no name]'}`);
                return;
            }

            logInfo(`Clicked outside ${debugName ?? '[no name]'}`);

            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            onClose();
        }

        const handleResize = () => {
            const thisParentRect = modalRef.current?.parentElement?.getBoundingClientRect() ?? { top: -999999, left: 0, width: 0, height: 0 }
            SetTop(thisParentRect.top ?? 0);
            SetLeft(thisParentRect.left ?? 0);
            SetWidth(thisParentRect.width ?? 0);
            SetHeight(thisParentRect.height ?? 0);
        }

        document.addEventListener('click', handleOutsideClick, false);
        document.addEventListener('keyup', handleEscapeKey, false);
        window.addEventListener('resize', handleResize, false);

        handleResize();

        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
            document.removeEventListener('keyup', handleEscapeKey, false);
            window.removeEventListener('resize', handleResize, false);
        };
    }, [modalRef, portalRef, onClose, debugName])

    let css: CSSProperties = { ...style, maxHeight: `calc(85vh - ${top}px)` };
    if ((align?.indexOf('right') ?? -1) !== -1) {
        css = { ...css, right: `calc(100% - ${left}px - ${width}px)` };
    } else {
        css = { ...css, left: `${left}px` };
    }

    if ((align?.indexOf('bottom') ?? -1) !== -1) {
        css = { ...css, top: `calc(${top}px + ${height}px)` };
    } else {
        css = { ...css, top: `${top}px` };
    }

    return (
        <div ref={modalRef}>
            <div className={styles.background} />

            {createPortal(
                <>
                    <div className={styles.modal} style={css} ref={portalRef} >
                        {children}
                    </div>
                </>
                ,
                document.body
            )}

        </div>
    );
}