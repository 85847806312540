import React from 'react';
import { EnumSetting } from './EnumSetting';
import { MarkedRegionsAppearance } from '../../store/graphicsSlice';
import { markedRegionsAppearanceSet, scaleSet } from '../../store/graphicsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { Stack } from '../Stack';
import { SettingsRow } from './SettingsRow';




export const ViewSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(ViewSettingsSection.name);

    const settings = useAppSelector(state => state.graphics);
    const dispatch = useAppDispatch();

    const scale = settings.ViewLayout === 'CharacterMapped' ? settings.CharacterMappedScale : (
        settings.ViewLayout === 'Interleaved' ? settings.InterleavedScale : (
            settings.ViewLayout === 'Continuous' ? settings.ContinuousScale : (
                settings.CharacterMappedScale
            )
        )
    );

    return (
        <Stack layout='vertical'>
            <SettingsRow label='Scale'>
                <EnumSetting
                    label="scale"
                    selectedOption={scale}
                    options={{ '1x': 1, '2x': 2, '3x': 3, '4x': 4 }}
                    order={['1x', '2x', '3x', '4x']}
                    onChange={scale => dispatch(scaleSet({ layout: settings.ViewLayout, scale }))}
                />
            </SettingsRow>

            <SettingsRow label='Dim Marked'>
                <EnumSetting
                    label="marked regions"
                    selectedOption={settings.MarkedRegionsAppearance}
                    options={{ 'Off': MarkedRegionsAppearance.Normal, 'On': MarkedRegionsAppearance.Dimmed }}
                    order={['Off', 'On']}
                    onChange={v => dispatch(markedRegionsAppearanceSet(v))}
                />
            </SettingsRow>
        </Stack>
    );
}