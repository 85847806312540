import * as React from "react";

import { VirtualList } from "./VirtualList";
import { useCallback, useEffect, useState } from "react";
import { currentLineAddressBoundsSet, selectCurrentLineStartAddress } from "../../store/codeSlice";
import { CentralHighlight } from "./CentralHightlight";
import { selectLineDatas } from "../../store/extraSelectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logInfo, logMajorComponentRender } from "../../classes/Logger";
import { selectActiveMemoryView } from "../../store/codeSlice";
import { MemoryAddress } from "../../classes/code/MemoryLocation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectActiveTool } from "../../store/toolSlice";
import { setRoute } from "../../App";

export const Bytes: React.FunctionComponent = () => {

    logMajorComponentRender(Bytes.name);

    const dispatch = useAppDispatch();
    const { lineDatas, itemSizes, totalItemSize, renderLine } = useAppSelector(selectLineDatas);
    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const currentLineStartAddress = useAppSelector(selectCurrentLineStartAddress);

    const [currentLineHeight, SetCurrentLineHeight] = useState(0);

    const { address, guid } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const tool = useAppSelector(selectActiveTool);

    // const handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {

    //     e.preventDefault();
    //     e.stopPropagation();
    //     e.nativeEvent.stopImmediatePropagation();

    //     dispatch(setSelection(0, 0));
    // }

    const setMiddleItem = useCallback((i: number) => {
        const lineData = lineDatas[i];
        if (lineData === undefined) { return; }

        const middleLineHeight = lineData.heightPx;
        SetCurrentLineHeight(middleLineHeight);

        const middleLineStartAddress = lineData.baseAddress;
        const middleLineEndAddress = middleLineStartAddress + Math.max(lineData.count - 1, 0);
        dispatch(currentLineAddressBoundsSet({ startAddress: { address: middleLineStartAddress, type: activeMemoryView }, endAddress: { address: middleLineEndAddress, type: activeMemoryView } }));
    }, [lineDatas, activeMemoryView, dispatch]);


    // Respond to 'navigate-to-address' event by setting route
    useEffect(() => {
        const listener = (e: CustomEvent<{ sources?: MemoryAddress[], target?: MemoryAddress, saveCurrentAddress?: boolean }>) => {
            if (e.detail == null) { return; }

            const { sources, target, saveCurrentAddress } = e.detail;

            // Special case : just want to update the route to point to the current line
            if (sources == null && target == null && saveCurrentAddress != null && saveCurrentAddress) {
                setRoute(navigate, location, { guid, tool, target: currentLineStartAddress });
                return;
            }

            if (target == null) { return; }

            const allSources: MemoryAddress[] = [];
            if (saveCurrentAddress != null && saveCurrentAddress) {
                allSources.push(currentLineStartAddress);
            }
            if (sources != null) {
                allSources.push(...sources)
            }
            setRoute(navigate, location, { guid, tool, target, sources: allSources });
        }

        document.addEventListener<any>('navigate-to-address', listener);
        return () => document.removeEventListener<any>('navigate-to-address', listener);
    }, [location, guid, tool, currentLineStartAddress, navigate])

    // Respond to route change by sending 'navigate' event
    useEffect(() => {
        const addressAsNumber = Number(`0x${address}`);
        if (address == null || addressAsNumber == null) { return; }

        // We see route location and param updates before store updates
        // address is a route param, but activeMemoryView has been set in the store by dispatch()
        // We see address updated first, which can be a problem when navigating between rom/ram views
        // So we hackily look for 'rom'/'ram' in the location to see if we need to wait for the store update to come through...
        const pathFragmentsAfterGUID = location.pathname.split('/').slice(location.pathname.startsWith('/') ? 3 : 2);
        if (pathFragmentsAfterGUID.indexOf(activeMemoryView) === -1) { return; }

        const middleLineData = lineDatas.filter(l => l.baseAddress <= addressAsNumber && l.baseAddress + l.count > addressAsNumber).shift();

        if (middleLineData !== undefined) {
            const halfFirstItemHeight = lineDatas[0].heightPx / 2;
            const offsetAtMiddleOfLine = middleLineData.offsetPx + (middleLineData.heightPx / 2) - halfFirstItemHeight;

            logInfo(`${Bytes.name} : navigating to address ${address}${activeMemoryView === 'rom' ? ' (ROM)' : ''}`);
            document.dispatchEvent(new CustomEvent<number>('navigate', { detail: offsetAtMiddleOfLine }));
        }
    }, [location, lineDatas, activeMemoryView, address])

    return (
        <>
            <CentralHighlight height={currentLineHeight} />
            <VirtualList
                itemSizes={itemSizes}
                totalItemSize={totalItemSize}
                renderItem={renderLine}
                setMiddleItem={setMiddleItem}
            />
        </>
    );
}
