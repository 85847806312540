import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer } from "../classes/Logger";

type Page = 'home' | 'project';
export type UIState = {
    currentPage: Page;
    recentlyClickedAddress: number;
    memoryMapHeight: number;
}

const initialState: UIState = {
    currentPage: 'home',
    recentlyClickedAddress: -1,
    memoryMapHeight: -1
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        currentPageSet(state, action: PayloadAction<Page>) {
            logReducer('uiSlice', 'currentPageSet');
            state.currentPage = action.payload;
        },

        recentlyClickedAddressAdded(state, action: PayloadAction<number>) {
            logReducer('uiSlice', 'recentlyClickedAddressAdded');
            state.recentlyClickedAddress = action.payload;
        },

        memoryMapHeightSet(state, action: PayloadAction<number>) {
            logReducer('uiSlice', 'memoryMapHeightSet');
            state.memoryMapHeight = action.payload;
        }
    }
});

export const { currentPageSet, recentlyClickedAddressAdded, memoryMapHeightSet } = uiSlice.actions;
export default uiSlice.reducer;

export const selectRecentlyClickedAddresses = (state: RootState) => state.ui.recentlyClickedAddress;

export const selectCurrentPage = (state: RootState) => state.ui.currentPage;