
import { C64Shader } from './C64Shader';
import { GraphicData } from './GraphicData';
import { Viewport } from '../../store/rendererSlice';
import { GraphicCommand } from '../commands/GraphicCommand';
import { newGraphicCommand } from "../commands/GraphicCommandHelpers";
import { MemoryLocation } from '../code/MemoryLocation';

export type OnDraw = () => GraphicData | undefined;


export class GraphicsRendererModel {

    private gl?: WebGL2RenderingContext;
    private c64Shader?: C64Shader;
    private onDraws: Array<OnDraw>;
    private selectionAddress: number;
    private selectionCount: number;
    private previewSettings?: GraphicCommand;
    private dimMarked: boolean;
    private hideSelection: boolean;
    private viewports: Viewport[];

    constructor() {
        this.onDraws = [];
        this.selectionAddress = 0;
        this.selectionCount = 0;
        this.viewports = [];
        this.previewSettings = newGraphicCommand({ address: 0x0000, countBytes: 1, widthPx: 8, heightPx: 8, scale: 1, graphicsType: 'CharacterMappedHiresFixed' });
        this.dimMarked = true;
        this.hideSelection = false;
    }

    init(gl: WebGL2RenderingContext) {

        if (gl === this.gl)
            return;

        // Build shader program
        this.gl = gl;
        this.c64Shader = new C64Shader(gl);
    }

    public setMemoryUsageData = (memoryUsageData: boolean[]) => {
        if (this.c64Shader == null) { return; }

        this.c64Shader.SetMemoryUsageTextureData(memoryUsageData);
    }

    public setMemoryMap = (memory: MemoryLocation[], height: number) => {
        if (this.c64Shader == null) { return; }
        if (height <= 0) { return; }

        const map = new Uint8Array(height);
        for (let row = 0; row < height; row++) {
            const thisRowStartAddress = Math.floor(row * 0x10000 / height);
            const nextRowStartAddress = Math.floor((row + 1) * 0x10000 / height);

            let rowHasData = false;
            let rowHasCode = false;
            let rowHasGfx = false;
            for (let address = thisRowStartAddress; address < nextRowStartAddress; address++) {
                if (memory[address] == null) { continue; }
                rowHasData ||= memory[address].IsData;
                rowHasCode ||= (memory[address].IsCode || memory[address].IsArgument);
                rowHasGfx ||= memory[address].IsGfx;
            }

            map[row] = 0;
            map[row] |= rowHasData ? 1 << 0 : 0;
            map[row] |= rowHasCode ? 1 << 1 : 0;
            map[row] |= rowHasGfx ? 1 << 2 : 0;
        }

        this.c64Shader.SetMemoryMapTextureData(height, map);
    }

    public setMemory = (RAM: Uint8Array, COLRAM: Uint8Array) => {
        if (this.c64Shader == null) { return; }

        this.c64Shader.SetMemoryTextureData(RAM);
        this.c64Shader.SetColourTextureData(COLRAM);
    }

    public setSelection = (selectionAddress: number, selectionCount: number) => {
        this.selectionAddress = selectionAddress;
        this.selectionCount = selectionCount;
    }

    public setPreviewSettings = (settings?: GraphicCommand) => {
        this.previewSettings = settings;
    }

    public setDimMarked = (dimMarked: boolean) => {
        this.dimMarked = dimMarked;
    }

    public setHideSelection = (hideSelection: boolean) => {
        this.hideSelection = hideSelection;
    }

    public setViewports = (viewports: Viewport[]) => {
        this.viewports = viewports;
    }

    public registerOnDraw = (onDrawToRegister: OnDraw) => {
        this.onDraws.push(onDrawToRegister);
    };

    public deregisterOnDraw = (onDrawToDeregister: OnDraw) => {
        this.onDraws = this.onDraws.filter(onDraw => onDraw !== onDrawToDeregister);
    };

    draw(timestampMS: number) {
        const shader = this.c64Shader;
        if (shader == null) { return; }

        const gl = this.gl;
        if (gl == null) { return; }

        const dpr = window.devicePixelRatio;
        const selectionStartAddress = this.selectionAddress;

        shader.SetDevicePixelRatio(dpr);
        shader.SetSelectionRange(selectionStartAddress, this.selectionCount);

        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

        shader.StartFrame(timestampMS);

        const viewports = this.viewports;
        viewports.forEach((v, i) => {
            const dv = {
                left: v.left * dpr,
                bottom: gl.drawingBufferHeight - (v.top + v.height) * dpr,
                width: v.width * dpr,
                height: v.height * dpr
            };
            gl.enable(gl.SCISSOR_TEST);
            gl.scissor(dv.left, dv.bottom, dv.width, dv.height);
            gl.clearColor(0, 0, 0, 0);
            gl.clear(gl.COLOR_BUFFER_BIT);

            const { selected, marked } = v.show;

            shader.Draw(this.onDraws, v, selected && !this.hideSelection, marked && this.dimMarked, this.previewSettings);

            gl.disable(gl.SCISSOR_TEST);
        });
    }
}