import React from 'react';

import styles from './SettingsPanel.module.css';

import { ViewSettingsSection } from './SettingsPanel/ViewSettingsSection';
import { ColoursSettingsSection } from './SettingsPanel/ColoursSettingsSection';
import { SelectionSettingsSection } from './SettingsPanel/SelectionSettingsSection';
import { CharacterSetSettingsSection } from './SettingsPanel/CharacterSetSettingsSection';
import { selectActiveTool } from '../store/toolSlice';
import { DisassemblyTimingSettingsSection } from './SettingsPanel/MarkupSettingsSection';
import { logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';
import { GraphicsModeSettingsSection } from './SettingsPanel/GraphicsModeSettingsSection';
import { ColoursSourcesSettingsSection } from './SettingsPanel/ColoursSourcesSettingsSection';
import { GraphicsWidthSettingsSection } from './SettingsPanel/GraphicsWidthSettingsSection';
import { RibbonMenu } from './RibbonMenu';
import { FormattingMarkupSettings } from './SettingsPanel/FormattingMarkupSettings';
import { PointerMarkupSettings } from './SettingsPanel/PointerMarkupSettings';
import { EntryPointMarkupSettings } from './SettingsPanel/EntryPointMarkupSettings';
import { LabelMarkupSettings } from './SettingsPanel/LabelMarkupSettings';
import { CommentMarkupSettings } from './SettingsPanel/CommentMarkupSettings';
import { DataRangeMarkupSettings } from './SettingsPanel/DataRangeMarkupSettings';
import { OverrideMarkupSettings } from './SettingsPanel/OverrideMarkupSettings';
import { useAppSelector } from '../store/hooks';


export const SettingsPanel: React.FunctionComponent = (props) => {

    const activeTool = useAppSelector(selectActiveTool);

    logMajorComponentRender(SettingsPanel.name, `with active tool ${activeTool}`);

    return (
        <>
            {(activeTool === 'graphics' || activeTool === 'library') &&
                <RibbonMenu cssClassName={styles.panel}>
                    <Stack layout='vertical'>
                        <SelectionSettingsSection />
                        <GraphicsWidthSettingsSection />
                    </Stack>
                    <GraphicsModeSettingsSection />
                    <ColoursSourcesSettingsSection />
                    <ColoursSettingsSection />
                    <CharacterSetSettingsSection />
                    <ViewSettingsSection />
                </RibbonMenu>
            }

            {activeTool === 'code' &&
                <RibbonMenu cssClassName={styles.panel}>
                    <Stack layout='vertical'>
                        <SelectionSettingsSection />
                        <DisassemblyTimingSettingsSection />
                    </Stack>
                    <Stack layout='vertical'>
                        <FormattingMarkupSettings />
                        <EntryPointMarkupSettings />
                    </Stack>
                    <Stack layout='vertical'>
                        <DataRangeMarkupSettings />
                        <PointerMarkupSettings />
                    </Stack>
                    <Stack layout='vertical'>
                        <CommentMarkupSettings />
                        <LabelMarkupSettings />
                    </Stack>
                    <Stack layout='vertical'>
                        <OverrideMarkupSettings />
                    </Stack>
                </RibbonMenu>
            }

        </>
    );
}