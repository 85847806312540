import React from 'react';
import { RootState } from '../store';
import { nameSet } from '../store/projectSlice';
import styles from './ProjectNamePanel.module.css';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';

export const ProjectNamePanel: React.FunctionComponent = (props) => {

    logMajorComponentRender(ProjectNamePanel.name);

    const project = useAppSelector((state: RootState) => state.project);
    const dispatch = useAppDispatch();

    const handleNameChange = (name: string) => {

        if (name === project.name) { return; }

        dispatch(nameSet(name));
    }

    return (
        <input
            type="text"
            value={project.name}
            onChange={e => handleNameChange(e.target.value)}
            className={styles.input}
            placeholder="untitled"
            spellCheck={false}
        />
    );
}