// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtscOL2QNPJ2GaOP8iHLRQY4DIHOY1G2U",
  authDomain: "46c.io",
  databaseURL: "https://proj-46cio.firebaseio.com",
  projectId: "proj-46cio",
  storageBucket: "proj-46cio.appspot.com",
  messagingSenderId: "899853004931",
  appId: "1:899853004931:web:5fb6c9351fa9924caaebb6",
  measurementId: "G-M49S05ZJT9"
};

const isDebug = (window.location.hostname === "localhost");


// Initialize Firebase
// -------------------
export const firebaseApp = initializeApp(firebaseConfig);


// Setup AppCheck
// --------------
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

if (isDebug) {
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider("6LeQfMIpAAAAAKUMPjFcmS2rESgIjftB6tipG8l0"),
  isTokenAutoRefreshEnabled: true
});


// Setup Analytics
// ---------------
const analytics = getAnalytics(firebaseApp);

export const logAnalyticsEvent = (message: string) => {

  const isDevelopment = process.env.NODE_ENV === 'development';
  const pre = isDevelopment ? '[dev] ' : '';

  logEvent(analytics, `${pre}${message}`);
}

const isDev = () => process.env.NODE_ENV === 'development';

type ProjectActionType = 'none'
  | 'new_project' | 'new_project_from_snapshot_file' | 'new_project_from_project_file'
  | 'open_project' | 'save_project' | 'delete_project' | 'export_project'
  | 'duplicate_project' | 'duplicate_cloud_project_to_local' | 'duplicate_local_project_to_cloud'
  ;


const getGuidAndName = (projectGuid: string, projectName?: string) => [projectGuid, projectName].join('|');

export const logProjectActionAnalytic = (operation: ProjectActionType, projectGuid: string, projectName?: string) => {
  const projectGuidAndName = getGuidAndName(projectGuid, projectName);
  logEvent(analytics, operation, { projectGuid, projectName, projectGuidAndName, dev: isDev() });
}

type FileActionType = 'none'
  | 'open_cloud_file' | 'save_cloud_file' | 'duplicate_cloud_file' | 'delete_cloud_file'
  | 'open_local_file' | 'save_local_file' | 'duplicate_local_file' | 'delete_local_file'
  ;

type FileActionResultType = 'success' | 'failure';

export const logFileActionResultAnalytic = (operation: FileActionType, fileOperationResult: FileActionResultType, projectGuid: string, projectName?: string) => {
  const projectGuidAndName = getGuidAndName(projectGuid, projectName);
  logEvent(analytics, operation, { projectGuid, projectName, projectGuidAndName, fileOperationResult, dev: isDev() });
}

type CommandOperationType = 'add_command' | 'edit_command' | 'delete_command';
type CommandType = 'unknown' | 'graphic' | 'formatting' | 'entry-point' | 'data' | 'pointer' | 'comment' | 'label' | 'override';

export const toCommandType = (commandStr: string) => {
  if (commandStr === 'graphic' || commandStr === 'formatting' || commandStr === 'entry-point' || commandStr === 'data' || commandStr === 'pointer' || commandStr === 'comment' || commandStr === 'label' || commandStr === 'override') { return commandStr as CommandType; }
  else { return 'unknown'; }
}

export const logCommandAnalytic = (operation: CommandOperationType, commandType: CommandType, address: number, projectGuid: string, projectName: string) => {
  const projectGuidAndName = getGuidAndName(projectGuid, projectName);
  logEvent(analytics, operation, { projectGuid, projectName, projectGuidAndName, commandType, address, dev: isDev() });
}