import styles from './HomePage.module.css';
import imageCreatures from '../images/homepage-creatures.png';
import imageDisassembly from '../images/homepage-disassembly.png';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

export const Banner: React.FC<{ message: string, colours: string[], background: string, updateMS: number }> = ({ message, colours, background, updateMS }) => {
    const [text, SetText] = useState(<>{message}</>);

    useEffect(() => {

        let t: NodeJS.Timeout;
        let index = 0;

        const callback = () => {

            const front = message.slice(index);
            const back = message.slice(0, index);
            index++;
            if (index === message.length)
                index = 0;

            const scrolledText = [...`< ${front}${back} >`].map((l, i) => {
                const colIndex = i % colours.length;
                const colourName = `--colour-${colours[colIndex]}`;
                return <span key={i} style={{ color: `var(${colourName})`, backgroundColor: `var(--colour-${background})` }}>{l}</span>
            });

            SetText(<>{scrolledText}  </>)

            t = setTimeout(callback, updateMS);
        }

        callback();

        return () => clearTimeout(t);
    }, [SetText, message, colours, background, updateMS]);

    return <>{text}</>;
}

export const HomePage: React.FC = () => {

    return (
        <div className={styles.container}>
            <div className={styles.column}>

                <h2><Banner message={'** PRE-RELEASE! '} colours={['04', '0D', '0F', '07']} background={'0A'} updateMS={250} /></h2>

                <h1>What</h1>

                <h3>
                    46C is a web-app for 'reverse engineering' Commodore 64 programs and games - exploring and documenting how software worked on one of the most popular machines of home computing's golden age.
                </h3>

                <p>
                    It presents RAM as the VIC-II would interpret it for character mapped, bitmapped and sprite graphics and lets you 'rip' graphics to a library when interesting areas are discovered. It disassembles 6510 code at lightning speed from given entry points, which can then be marked-up with labels, comments, formatting and other hints.
                </p>

                <p>
                    It saves projects to browser local storage by default, and can save online if logged in with an existing Google or Github account.
                </p>

                <img src={imageCreatures} alt='' />

                <h1>Why</h1>

                <p>
                    This kind of code archaeology lets you play digital detective, hunting down meaning in a sea of hex, following the footsteps and decisions of fellow coders from a different time and ultimately unpicking a puzzle created 40-odd years ago. The scale of 8-bit games is perfect : the platform's constraints led to some truly fascinating techniques, while the programs are often small enough to understand in their entirety.
                </p>

                <p>
                    Many great tools have been created over the years for reverse engineering the C64. 46C is an attempt at a modern, accessible and immediate way into this incredibly fun and satisfying hobby.
                </p>

                <img src={imageDisassembly} alt='' />

                <h1>How</h1>

                <p>
                    Proper instructions and documentation coming soon - follow <a href='https://x.com/46cio' target='_blank' rel="noreferrer">@46cio</a> for updates!
                </p>

                <p>
                    Until then : get started by loading your favourite game in <a href='https://vice-emu.sourceforge.io/index.html#download' target='_blank' rel="noreferrer">Vice emulator</a> (preferably the recent version 3.8) and making a snapshot. This will save a .vsf file, which you can open in 46c by clicking on the Project menu then 'Add from file and open...'. There are also some example snapshots included at the bottom of the Project menu.
                </p>

                <p>
                    Graphics : play with the mode settings along the top of the window to identify interesting things lurking in memory. In character-mapped view, clicking on a character set will draw all character-mapped screens using that set; in bitmapped view clicking on a colour bank will draw all bitmaps using those colours. If you're not too familiar with C64 graphic modes, sprites are probably the easiest to start with.
                </p>

                <p>
                    Code : the general idea is to select either a single byte or a range (using shift+click to extend your selection) then apply markup using the options along the top of the screen. Memory is fully disassembled from scratch after each change, so it's very quick and easy to uncover code by toggling entry points. All changes are stored as a searchable list of commands.
                </p>

                <h1>Who</h1>

                <p>
                    46c is the work of <a href='https://linkedin.com/in/robindeitch' target='_blank' rel="noreferrer">Robin Deitch</a> and reflects a multi-decade fascination with the 8-bit world.
                </p>

                <p>
                    Stay in touch for updates, bug reporting, suggestions and human contact : <a href='https://x.com/46cio' target='_blank' rel="noreferrer">@46cio</a> / <a href='mailto:hello@46c.io' target='_blank' rel="noreferrer">hello@46c.io</a>.
                </p>

                <p>
                    Enjoy! <FontAwesomeIcon icon={faHeart} color='red' />
                </p>
            </div>
        </div >
    );
}