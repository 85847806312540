import { useState, MouseEvent, PropsWithChildren, useEffect, createRef } from "react";
import styles from './RibbonMenu.module.css';
import { cx } from "../classes/Utils";
import { Modal } from "./SettingsPanel/Modal";
import { Stack } from "./Stack";
import React from "react";
import { logMajorComponentRender } from "../classes/Logger";

const RibbonMenuItem: React.FC<React.PropsWithChildren<{ onMeasure: (x: number) => void }>> = (props) => {
    const ref = createRef<HTMLDivElement>();
    const { children, onMeasure } = props;

    useEffect(() => {
        if (ref.current == null) { return; }

        const resizeObserver = new ResizeObserver((entries) => {
            onMeasure(entries[0].borderBoxSize[0].inlineSize);
        });

        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect();

    }, [ref, onMeasure]);

    return <div ref={ref}>{children}</div>
};



export const RibbonMenu: React.FC<PropsWithChildren<{ cssClassName?: string }>> = (props) => {

    logMajorComponentRender(RibbonMenu.name);

    const { children, cssClassName } = props;

    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);

    const handleOpenModal = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetModalIsOpen(true);
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
    }


    const childCount = React.Children.count(children);
    const [wrappedChildren, SetWrappedChildren] = useState<JSX.Element[] | null | undefined>([]);
    const [minWidthMatchesState, SetMinWidthMatchesState] = useState<boolean[]>(new Array<boolean>(childCount).fill(true));

    useEffect(() => {

        logMajorComponentRender(RibbonMenu.name, 'Entering effect');

        const widths: { [index: number]: number } = {};
        let minWidthMatches: MediaQueryList[] = [];
        let minWidthListeners: ((e: MediaQueryListEvent) => void)[] = [];

        const handleMinWidthChange = (e: MediaQueryListEvent, i: number) => {

            const min: boolean[] = [];
            for (let index = 0; index < i; index++) {
                min[index] = true;
            }
            min[i] = e.matches;
            if (i < childCount - 1) {
                for (let index = i + 1; index < childCount; index++) {
                    min[index] = false;
                }
            }

            logMajorComponentRender(RibbonMenu.name, `[handleMinWidthChange()] child ${i} == ${e.matches} @ ${e.media} (now ${min.join(' ')})`);
            SetMinWidthMatchesState(min);
        }

        const handleOnMeasure = (width: number, index: number) => {

            logMajorComponentRender(RibbonMenu.name, `[handleOnMeasure()] Measuring ${index}`);

            widths[index] = width;
            if (Object.keys(widths).length < childCount) {
                logMajorComponentRender(RibbonMenu.name, `[handleOnMeasure()] Only got ${Object.keys(widths).length} widths for ${childCount} children`);
                return;
            }

            let sum = 4 + 4 + 35.6 + 8; // page margin, ribbon padding, burger menu, padding
            const dividerWidth = 0;
            const breakpoints = Object.keys(widths).map(k => { sum += (widths[Number(k)] + dividerWidth); return `${sum}px`; });
            logMajorComponentRender(RibbonMenu.name, `[handleOnMeasure()] Settings widths : ${Object.keys(widths).map(k => widths[Number(k)]).join(' ')}`);
            logMajorComponentRender(RibbonMenu.name, `[handleOnMeasure()] Breakpoints : ${breakpoints.join(' ')}`);

            if ((minWidthMatches.length === childCount) && (minWidthListeners.length === childCount)) {
                logMajorComponentRender(RibbonMenu.name, '[handleOnMeasure()] Removing old listeners');
                minWidthMatches.map((m, i) => m.removeEventListener('change', minWidthListeners[i]));
            }

            logMajorComponentRender(RibbonMenu.name, '[handleOnMeasure()] Creating new listeners');
            minWidthMatches = breakpoints.map(b => window.matchMedia(`(min-width: ${b})`));
            minWidthListeners = minWidthMatches.map((_, i) => (e: MediaQueryListEvent) => handleMinWidthChange(e, i));
            minWidthMatches.map((m, i) => m.addEventListener('change', minWidthListeners[i]));

            logMajorComponentRender(RibbonMenu.name, `[handleOnMeasure()] ${minWidthMatches.map(m => m.matches).join(' ')}`);
            SetMinWidthMatchesState(minWidthMatches.map(m => m.matches));
        }

        SetWrappedChildren(React.Children.map(children, (c, i) => <RibbonMenuItem onMeasure={w => handleOnMeasure(w, i)}>{c}</RibbonMenuItem>));


        return () => {
            logMajorComponentRender(RibbonMenu.name, 'Exiting effect');
            minWidthMatches.map((m, i) => m.removeEventListener('change', minWidthListeners[i]));
        }

    }, [children, childCount, SetWrappedChildren, SetMinWidthMatchesState]);

    const ribbonItems = React.Children.toArray(children).filter((_, i) => minWidthMatchesState[i]);
    const menuItems = React.Children.toArray(children).filter((_, i) => !minWidthMatchesState[i]);
    logMajorComponentRender(RibbonMenu.name, `Rendering - ${wrappedChildren?.length ?? 0} wrapped children, ${React.Children.count(ribbonItems)} ribbon items (${minWidthMatchesState.length} states)`);

    const showMenuToggle = !minWidthMatchesState[minWidthMatchesState.length - 1];

    return (
        <>
            <Stack layout='horizontal' alignItems='center' justifyContent='start' cssClassName={cx(cssClassName, styles.ribbon, styles.measurable)} divider={true}>
                {wrappedChildren}
            </Stack>

            <Stack layout='horizontal' alignItems='center' justifyContent='start' cssClassName={cx(cssClassName, styles.ribbon)} divider={true}>

                {ribbonItems}

                {showMenuToggle &&
                    <div style={{ position: 'relative' }}>

                        <button className={styles.button} onClick={handleOpenModal}>…</button>

                        {modalIsOpen &&
                            <Modal onClose={handleCloseModal} align='bottom-right' style={{ right: '0px' }} debugName={RibbonMenu.name}>
                                <Stack layout='vertical' cssClassName={cx(cssClassName, styles.ribbonMenu)}>
                                    {menuItems}
                                </Stack>
                            </Modal>
                        }
                    </div>
                }

            </Stack>
        </>
    );
}