import * as React from "react";
import styles from './HexNumberInput.module.css';

import { cx, Utils } from "../../classes/Utils";
import { ISettingProps } from "./ISettingProps";
import { logMinorComponentRender } from "../../classes/Logger";


export interface IHexNumberInputProps extends ISettingProps {
    onChange: (newNumber: number) => void;
    number: number;
    enabled: boolean;
    forceFourDigits: boolean;
    unit?: string;
}

const getNumberString = (number: number, forceFourDigits: boolean) => {
    return `${Utils.toHexString(number, forceFourDigits)}`;
}


export const HexNumberInput: React.FunctionComponent<IHexNumberInputProps> = (props) => {

    logMinorComponentRender(HexNumberInput.name);

    const initialNumberString = getNumberString(props.number, props.forceFourDigits);
    const [numberString, setNumberString] = React.useState<string>(initialNumberString);
    const [numberStringIsValid, setNumberStringIsValid] = React.useState<boolean>(true);
    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setNumberString(initialNumberString);
    }, [initialNumberString])

    React.useEffect(() => {
        if (isFocused) {
            inputRef.current?.select();
        }
    }, [inputRef, isFocused, initialNumberString]);


    const getFormattedNumberString = () => {

        if (!props.enabled) {
            return props.forceFourDigits ? ' ----' : ' --';
        }

        const shouldShowLeadingSymbol = (numberStringIsValid && !isFocused);
        const leadingSymbol = shouldShowLeadingSymbol ? "$" : "";

        return `${leadingSymbol}${numberString}`;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const str = e.target.value;
        setNumberString(str);

        let { valid } = Utils.hexStringToNumber(str);
        setNumberStringIsValid(valid);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Escape") {
            e.preventDefault();
            setNumberString(initialNumberString);
            setNumberStringIsValid(true);
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Enter") {
            e.preventDefault();
            changeValueIfValid();
        }
    }

    const handleBlur = () => {

        setIsFocused(false);
        changeValueIfValid();
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {

        setIsFocused(true);
    }


    const changeValueIfValid = () => {
        let { value, valid } = Utils.hexStringToNumber(numberString);
        if (valid) {

            setNumberString(getNumberString(value, props.forceFourDigits));
            setNumberStringIsValid(true);

            if (value !== props.number) {
                props.onChange(value);
            }
        }
    }

    return (
        <input
            ref={inputRef}
            type="text"
            disabled={!props.enabled}
            value={`${getFormattedNumberString()}`}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={cx(styles.numberInput, numberStringIsValid ? '' : styles.bad)}
            size={5}
        />
    );
}