import { logCommandAnalytic } from "../../classes/code/Firebase";
import { ReferencePart } from "../../classes/code/MemoryLocation";
import { newPointerCommand } from "../../classes/commands/PointerCommandHelpers";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commandAdded, commandRemoved, commandUpdated, selectGuidAndName, selectIndexedPointerCommands, selectMarkedUpMemory } from "../../store/projectSlice";
import { selectSelection } from "../../store/toolSlice";
import { EnumSetting } from "./EnumSetting";
import { HexNumberInput } from "./HexNumberInput";
import { SettingsRow } from "./SettingsRow";

export const PointerMarkupSettings: React.FC = () => {

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;
    const { ram } = useAppSelector(selectMarkedUpMemory);
    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';
    const { guid, name } = useAppSelector(selectGuidAndName);

    const dispatch = useAppDispatch();

    const indexedPointerCommands = useAppSelector(selectIndexedPointerCommands)
        .filter(c =>
            (c.command.part !== '16bit' && c.command.address >= selectionAddress && c.command.address <= selectionEndAddress)
            ||
            (c.command.part === '16bit' && c.command.address >= selectionAddress - 1 && c.command.address <= selectionEndAddress)
        );
    const { index: pointerCommandIndex, command: pointerCommand } = indexedPointerCommands.length === 1 ? indexedPointerCommands[0] : { index: -1, command: undefined };

    type PartOptions = ReferencePart | 'none' | 'mixed';
    let option: PartOptions = 'none';
    if (!isRAM || indexedPointerCommands.length === 0) {
        option = 'none';
    } else if (selectionCount === 1 && pointerCommand) {
        option = pointerCommand.part;
    } else if (selectionCount === 2 && pointerCommand && selectionAddress === pointerCommand.address && pointerCommand.part === '16bit') {
        option = '16bit';
    } else {
        option = 'mixed';
    }

    const handleChangePointerTargetAddress = (newAddress: number) => {
        if (!pointerCommand) { return; }
        const newCommand = newPointerCommand(pointerCommand.address, { address: newAddress, type: pointerCommand.target.type }, pointerCommand.part);
        logCommandAnalytic('edit_command', 'pointer', pointerCommand.address, guid, name);
        dispatch(commandUpdated({ commandIndexToUpdate: pointerCommandIndex, command: newCommand }));

        updateRouteToCurrentLine();
    }

    const handleChangePointerTargetType = (newType: 'ram' | 'rom') => {
        if (!pointerCommand) { return; }
        const newCommand = newPointerCommand(pointerCommand.address, { address: pointerCommand.target.address, type: newType }, pointerCommand.part);
        logCommandAnalytic('edit_command', 'pointer', pointerCommand.address, guid, name);
        dispatch(commandUpdated({ commandIndexToUpdate: pointerCommandIndex, command: newCommand }));

        updateRouteToCurrentLine();
    }

    const handlePointerPartChange = (newPart: PartOptions) => {
        if (pointerCommand == null && indexedPointerCommands.length === 0) {
            let targetAddress: number | undefined;
            if (newPart === 'lo') {
                targetAddress = ram[selectionAddress].Value;
            } else if (newPart === 'hi') {
                targetAddress = 0x0100 * ram[selectionAddress].Value;
            } else if (newPart === '16bit' && (selectionAddress + 1) < 0x10000) {
                targetAddress = ram[selectionAddress].Value + (0x0100 * ram[selectionAddress + 1].Value);
            }

            if (targetAddress == null) { return; }

            const newCommand = newPointerCommand(selectionAddress, { address: targetAddress, type: 'ram' }, newPart as ReferencePart);
            logCommandAnalytic('add_command', 'pointer', selectionAddress, guid, name);
            dispatch(commandAdded(newCommand));
        } else if (newPart === 'none') {
            indexedPointerCommands.sort((a, b) => a.index - b.index).reverse().forEach(c => {
                logCommandAnalytic('delete_command', 'pointer', c.command.address, guid, name);
                dispatch(commandRemoved(c.index));
            });
        } else if (pointerCommand != null) {
            const newCommand = newPointerCommand(pointerCommand.address, pointerCommand.target, newPart as ReferencePart);
            logCommandAnalytic('edit_command', 'pointer', pointerCommand.address, guid, name);
            dispatch(commandUpdated({ commandIndexToUpdate: pointerCommandIndex, command: newCommand }));
        }

        updateRouteToCurrentLine();
    }

    const validNoneOneByte = option === 'none' && selectionCount === 1;
    const validNoneTwoBytes = option === 'none' && selectionCount === 2;
    const valid16Bit = option === '16bit' && pointerCommand && selectionAddress === pointerCommand.address && selectionCount === 1;
    const valid = option === 'lo' || option === 'hi' || valid16Bit;

    const loProperty = isRAM && (validNoneOneByte || valid || (option === 'lo')) ? 'lo' : '';
    const hiProperty = isRAM && (validNoneOneByte || valid || (option === 'hi')) ? 'hi' : '';
    const wideProperty = isRAM && (validNoneTwoBytes || valid || (option === '16bit')) ? '16bit' : '';
    const mixedProperty = isRAM && (option === 'mixed') ? 'mixed' : '';

    const options = mixedProperty ? ['mixed'] : ['lo', 'hi', '16bit'];

    const memoryTypeOrder = isRAM && (pointerCommand != null) ? ['RAM', 'ROM'] : ['none'];

    return (
        <SettingsRow label="Pointer">
            <EnumSetting
                label="formatting"
                selectedOption={option}
                options={{ 'none': 'none', [loProperty]: 'lo', [hiProperty]: 'hi', [wideProperty]: '16bit', [mixedProperty]: 'mixed' }}
                order={['none', ...options]}
                onChange={handlePointerPartChange}
            />
            <HexNumberInput
                enabled={option !== 'none' && option !== 'mixed'}
                label="target"
                number={pointerCommand?.target.address ?? 0}
                onChange={handleChangePointerTargetAddress}
                forceFourDigits={true}
            />
            <EnumSetting
                label="memory"
                selectedOption={pointerCommand?.target.type}
                options={{ 'RAM': 'ram', 'ROM': 'rom' }}
                order={memoryTypeOrder}
                onChange={handleChangePointerTargetType}
            />
        </SettingsRow>
    );
}