import { getAuth } from "firebase/auth";
import { firebaseApp, logFileActionResultAnalytic } from '../classes/code/Firebase';
import { ProjectDescription, makeCopy } from "./ProjectDescription";
import { serialise as serialiseProjectDescription, deserialise as deserialiseProjectDescription } from './ProjectDescriptionHelpers';
import { UploadMetadata, getStorage, ref, uploadBytes, list, getMetadata, getBlob, deleteObject } from "firebase/storage";
import { ProjectInfo } from "./ProjectInfo";
import { logError } from "./Logger";

export const listAsync = async () => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;
    const storage = getStorage(firebaseApp);

    if (currentUser == null) { return; }

    // https://medium.com/firebase-developers/using-the-new-list-api-in-cloud-storage-for-firebase-12d667fdc130
    const listRef = ref(storage, `users/${currentUser.uid}`);
    const listRes = await list(listRef, { maxResults: 100 });

    const promises = listRes.items.map(async i => {

        const metadata = await getMetadata(i);
        const { customMetadata } = metadata;

        const hasMeta = (customMetadata != null);
        const info: ProjectInfo = {
            guid: hasMeta ? customMetadata['guid'] : 'no guid',
            commandCount: hasMeta ? Number(customMetadata['commandCount']) : 0,
            name: hasMeta ? customMetadata['name'] : 'no name',
            timestamp: hasMeta ? Number(customMetadata['time']) ?? Number(new Date(metadata.updated)) : 0,
            location: 'cloud'
        };

        return info;
    });

    const projectInfos = await Promise.all(promises);

    return projectInfos;
}

export const getAsync = async (guid: string) => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;
    const storage = getStorage(firebaseApp);

    if (currentUser == null) { return; }

    try {
        const fileRef = ref(storage, `users/${currentUser.uid}/${guid}.46c`);
        const fileRes = await getBlob(fileRef);
        const file = await fileRes.text();
        if (file == null) { throw new Error(`Can't load cloud project with guid ${guid}`); }

        const desc = deserialiseProjectDescription(file);
        if (desc == null) { throw new Error(`Can't load cloud project with guid ${guid}`); }

        logFileActionResultAnalytic('open_cloud_file', 'success', guid, desc?.name)

        return desc;

    } catch (error) {
        logFileActionResultAnalytic('open_cloud_file', 'failure', guid)

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}

export const duplicateAsync = async (guid: string) => {

    try {
        const file = await getAsync(guid);
        if (file == null) { throw new Error(`Can't load cloud project with guid ${guid}`); }

        const copy = makeCopy(file);

        await saveAsync(copy, Date.now());

        logFileActionResultAnalytic('duplicate_cloud_file', 'success', guid, file.name)

    } catch (error) {
        logFileActionResultAnalytic('duplicate_cloud_file', 'failure', guid)

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}


export const saveAsync = async (p: ProjectDescription, time: number) => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;
    const storage = getStorage(firebaseApp);

    if (currentUser == null) { return; }

    try {
        const fileBytes = new Blob([serialiseProjectDescription(p, time)]);
        const metaData: UploadMetadata = {
            customMetadata: {
                "name": p.name.replaceAll('|', '-'),
                "guid": p.guid,
                "commandCount": p.commands.length.toString(),
                "time": time.toString()
            }
        };

        const fileRef = ref(storage, `users/${currentUser.uid}/${p.guid}.46c`);
        await uploadBytes(fileRef, fileBytes, metaData);

        logFileActionResultAnalytic('save_cloud_file', 'success', p.guid, p.name)

    } catch (error) {
        logFileActionResultAnalytic('save_cloud_file', 'failure', p.guid, p.name)

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}

export const deleteAsync = async (guid: string) => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;
    const storage = getStorage(firebaseApp);

    if (currentUser == null) { return; }

    try {
        const fileRef = ref(storage, `users/${currentUser.uid}/${guid}.46c`);
        await deleteObject(fileRef);

        logFileActionResultAnalytic('delete_cloud_file', 'success', guid)

    } catch (error) {
        logFileActionResultAnalytic('delete_cloud_file', 'failure', guid)

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}