import React, { ReactNode } from 'react';
import styles from './SettingsRow.module.css';
import { logMinorComponentRender } from '../../classes/Logger';


export const SettingsRow: React.FunctionComponent<{ children: ReactNode, label: string }> = (props) => {

    logMinorComponentRender(SettingsRow.name);

    const cells = React.Children.map(props.children, child => {
        return <span className={styles.child}>{child}</span>;
    });

    return <div className={styles.row}><span className={styles.label}>{props.label}</span> : {cells}</div>;
}