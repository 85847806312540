import React, { useMemo } from 'react';
import { GraphicsRendererModel } from '../../classes/graphics/GraphicsRendererModel';
import { RootState } from '../../store';
import { registerOnTick, setOnDrawHandlers } from '../../classes/graphics/RendererStore';
import { selectMarkedUpMemory, selectCOLRAM, selectRAM } from '../../store/projectSlice';
import { selectActiveTool, selectSelection } from '../../store/toolSlice';
import { selectViewports } from '../../store/rendererSlice';
import { previewCommandSelector, selectDimMarked } from '../../store/graphicsSlice';
import { useAppSelector } from '../../store/hooks';
import { getGl } from './GraphicsCanvas';
import { logInfo, logMajorComponentRender } from '../../classes/Logger';
import { selectActiveMemoryView } from '../../store/codeSlice';
import { getROMBytes } from '../../classes/code/MemoryManager';


export const GraphicsRenderer: React.FunctionComponent = () => {

    logMajorComponentRender(GraphicsRenderer.name);

    const modelRef = React.useRef<GraphicsRendererModel>(new GraphicsRendererModel());
    const RAMBytes = useAppSelector(selectRAM);
    const COLRAMBytes = useAppSelector(selectCOLRAM);
    const ROMBytes = getROMBytes(COLRAMBytes);
    const { selectionAddress, selectionCount, selectionAddressROM, selectionCountROM } = useAppSelector(selectSelection);
    const viewports = useAppSelector(selectViewports);
    const { ram, rom } = useAppSelector(selectMarkedUpMemory);
    const memoryView = useAppSelector(selectActiveMemoryView);
    const previewSettings = useAppSelector(previewCommandSelector);
    const dimMarked = useAppSelector(selectDimMarked);
    const memoryMapHeight = useAppSelector((state: RootState) => state.ui.memoryMapHeight);

    const tool = useAppSelector(selectActiveTool);

    const gl = getGl();

    const onTick = useMemo(() => (timestampMS: number) => {
        modelRef.current.draw(timestampMS);
    }, [modelRef]);

    React.useEffect(() => {
        if (gl == null) {
            logInfo(`${GraphicsRenderer.name} : GL not available`);
            return;
        }

        logInfo(`${GraphicsRenderer.name} : setting up model registerOnDraw/deregisterOnDraw`);

        const model = modelRef.current;
        model.init(gl);
        setOnDrawHandlers(model.registerOnDraw, model.deregisterOnDraw);


        logInfo(`${GraphicsRenderer.name} : setting up model draw onTick`);

        registerOnTick(onTick);

    }, [gl, modelRef, onTick]);

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing RAM/COLRAM updates to model`);
        const mem = (tool !== 'code') || (memoryView === 'ram') ? RAMBytes : ROMBytes;
        modelRef.current.setMemory(Uint8Array.from(mem), Uint8Array.from(COLRAMBytes));
    }, [RAMBytes, ROMBytes, COLRAMBytes, memoryView, tool])

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing memory usage updates to model (for dimming)`);
        const memoryUsageData = ram.map(m => m.IsCode || m.IsArgument || m.IsData || m.IsGfx);
        modelRef.current.setMemoryUsageData(memoryUsageData);
    }, [ram]);

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing memory map updates to model`);
        modelRef.current.setMemoryMap(memoryView === 'ram' ? ram : rom, memoryMapHeight);
    }, [ram, rom, memoryView, memoryMapHeight])

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing selection address/count updates to model`);
        const useRAM = (tool !== 'code') || (memoryView === 'ram');
        const address = useRAM ? selectionAddress : selectionAddressROM;
        const count = useRAM ? selectionCount : selectionCountROM;
        modelRef.current.setSelection(address, count);
    }, [selectionAddress, selectionCount, selectionAddressROM, selectionCountROM, memoryView, tool])

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing preview settings updates to model`);
        modelRef.current.setPreviewSettings(previewSettings ?? undefined);
    }, [previewSettings]);

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing 'dim marked' and 'hide selection' updates to model`);
        modelRef.current.setDimMarked(dimMarked && tool !== 'library' && tool !== 'code');
        modelRef.current.setHideSelection(tool === 'library');
    }, [dimMarked, tool]);

    React.useEffect(() => {
        logInfo(`${GraphicsRenderer.name} : passing viewport updates to model`);
        modelRef.current.setViewports(viewports);
    }, [viewports])

    return null;
}