import bubbleBobbleSnapshot from '../data/bubblebobble.vsf?bin';
import montezumasRevengeSnapshot from '../data/montezumasrevenge.vsf?bin';
import creaturesSnapshot from '../data/creatures.vsf?bin';

function dataURLtoUint8Array(dataurl: string) {
    const arr = dataurl.split(",");
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const array = new Uint8Array(n);
    while (n--) {
        array[n] = bstr.charCodeAt(n);
    }
    return array;
}

export const bubbleBobbleBytes = dataURLtoUint8Array(bubbleBobbleSnapshot);
export const montezumasRevengeBytes = dataURLtoUint8Array(montezumasRevengeSnapshot);
export const creaturesBytes = dataURLtoUint8Array(creaturesSnapshot);

