import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Palette } from "../classes/Palette";
import { GraphicCommand } from "../classes/commands/GraphicCommand";
import { graphicsTypeToComponents } from "../classes/Utils";
import { RootState } from ".";
import { selectSelection } from "./toolSlice";
import { newGraphicCommandFromState } from "../classes/commands/GraphicCommandHelpers";
import { ColourMode, ColourSource, GraphicsTypes, ViewLayout } from "../classes/graphics/GraphicsEnums";
import { logReducer, logSelector } from "../classes/Logger";

const widthChars = (command: GraphicCommand) => Math.ceil(command.widthPx / 8);


export enum MarkedRegionsAppearance { Normal, Dimmed }
export enum ColourProperties {
    "None",
    "InterleavedHiresBackground00",
    "InterleavedHiresForeground01",
    "InterleavedMultiColourBackground00",
    "InterleavedMultiColourForeground01",
    "InterleavedMultiColourForeground10",
    "InterleavedMultiColourForeground11",
    "ContinuousHiresBackground00",
    "ContinuousHiresForeground01",
    "ContinuousMultiColourBackground00",
    "ContinuousMultiColourForeground01",
    "ContinuousMultiColourForeground10",
    "ContinuousMultiColourForeground11",
    "CharacterMappedHiresBackground00",
    "CharacterMappedHiresForeground01",
    "CharacterMappedMultiColourBackground00",
    "CharacterMappedMultiColourForeground01",
    "CharacterMappedMultiColourForeground10",
    "CharacterMappedMultiColourForeground11",
    "CharacterMappedExtendedColourBackground00",
    "CharacterMappedExtendedColourBackground01",
    "CharacterMappedExtendedColourBackground10",
    "CharacterMappedExtendedColourBackground11",
    "CharacterMappedExtendedColourForeground01",
    "CharacterMappedCharacterSetHiresBackground00",
    "CharacterMappedCharacterSetHiresForeground01",
    "CharacterMappedCharacterSetMultiColourBackground00",
    "CharacterMappedCharacterSetMultiColourForeground01",
    "CharacterMappedCharacterSetMultiColourForeground10",
    "CharacterMappedCharacterSetMultiColourForeground11"
}

export type GraphicsState = {
    ViewLayout: ViewLayout,


    InterleavedMode: ColourMode,
    InterleavedMainColourSource: ColourSource,
    InterleavedMainColourAddressRAM: number,
    InterleavedOtherColourSource: ColourSource,
    InterleavedOtherColourAddressRAM: number,
    InterleavedOtherColourAddressCOLRAM: number,

    InterleavedHiresBackground00: number,
    InterleavedHiresForeground01: number,

    InterleavedMultiColourBackground00: number,
    InterleavedMultiColourForeground01: number,
    InterleavedMultiColourForeground10: number,
    InterleavedMultiColourForeground11: number,
    InterleavedScale: number;
    InterleavedWidthChars: number,


    ContinuousMode: ColourMode,

    ContinuousHiresBackground00: number,
    ContinuousHiresForeground01: number,

    ContinuousMultiColourBackground00: number,
    ContinuousMultiColourForeground01: number,
    ContinuousMultiColourForeground10: number,
    ContinuousMultiColourForeground11: number,
    ContinuousScale: number;
    ContinuousWidthChars: number,


    CharacterMappedMode: ColourMode,
    CharacterMappedMainColourSource: ColourSource,
    CharacterMappedMainColourAddressCOLRAM: number,
    CharacterMappedMainColourAddressRAM: number,

    CharacterMappedHiresBackground00: number,
    CharacterMappedHiresForeground01: number,

    CharacterMappedMultiColourBackground00: number,
    CharacterMappedMultiColourForeground01: number,
    CharacterMappedMultiColourForeground10: number,
    CharacterMappedMultiColourForeground11: number,

    CharacterMappedExtendedColourBackground00: number,
    CharacterMappedExtendedColourBackground01: number,
    CharacterMappedExtendedColourBackground10: number,
    CharacterMappedExtendedColourBackground11: number,
    CharacterMappedExtendedColourForeground01: number,

    CharacterMappedCharacterSetAddress: number,
    CharacterMappedCharacterSetMode: ColourMode
    CharacterMappedCharacterSetHiresBackground00: number,
    CharacterMappedCharacterSetHiresForeground01: number,
    CharacterMappedCharacterSetMultiColourBackground00: number,
    CharacterMappedCharacterSetMultiColourForeground01: number,
    CharacterMappedCharacterSetMultiColourForeground10: number,
    CharacterMappedCharacterSetMultiColourForeground11: number,

    CharacterMappedScale: number,
    CharacterMappedWidthChars: number,

    PickingColour: ColourProperties;

    MarkedRegionsAppearance: MarkedRegionsAppearance;
}

const initialState: GraphicsState = {
    ViewLayout: 'CharacterMapped',

    InterleavedMode: 'HiRes',
    InterleavedMainColourSource: 'MainRAM',
    InterleavedMainColourAddressRAM: 0x0400,
    InterleavedOtherColourSource: 'ColourRAM',
    InterleavedOtherColourAddressRAM: 0x0800,
    InterleavedOtherColourAddressCOLRAM: 0x0000,
    InterleavedHiresBackground00: Palette.Black.index,
    InterleavedHiresForeground01: Palette.LightGrey.index,
    InterleavedMultiColourBackground00: Palette.Cyan.index,
    InterleavedMultiColourForeground01: Palette.Black.index,
    InterleavedMultiColourForeground10: Palette.LightGrey.index,
    InterleavedMultiColourForeground11: Palette.White.index,
    InterleavedScale: 1,
    InterleavedWidthChars: 40,

    ContinuousMode: 'HiRes',
    ContinuousHiresBackground00: Palette.Green.index,
    ContinuousHiresForeground01: Palette.LightGreen.index,
    ContinuousMultiColourBackground00: Palette.Black.index,
    ContinuousMultiColourForeground01: Palette.Blue.index,
    ContinuousMultiColourForeground10: Palette.Red.index,
    ContinuousMultiColourForeground11: Palette.Orange.index,
    ContinuousScale: 3,
    ContinuousWidthChars: 3,

    CharacterMappedMode: 'HiRes',
    CharacterMappedMainColourSource: 'Fixed',
    CharacterMappedMainColourAddressRAM: 0x0400,
    CharacterMappedMainColourAddressCOLRAM: 0x0000,

    CharacterMappedHiresBackground00: Palette.Grey.index,
    CharacterMappedHiresForeground01: Palette.LightGrey.index,

    CharacterMappedMultiColourBackground00: Palette.LightBlue.index,
    CharacterMappedMultiColourForeground01: Palette.Magenta.index,
    CharacterMappedMultiColourForeground10: Palette.Orange.index,
    CharacterMappedMultiColourForeground11: Palette.LightGrey.index,

    CharacterMappedExtendedColourBackground00: Palette.Black.index,
    CharacterMappedExtendedColourBackground01: Palette.DarkGrey.index,
    CharacterMappedExtendedColourBackground10: Palette.Grey.index,
    CharacterMappedExtendedColourBackground11: Palette.LightGrey.index,
    CharacterMappedExtendedColourForeground01: Palette.White.index,

    CharacterMappedCharacterSetAddress: 0x2000,
    CharacterMappedCharacterSetHiresBackground00: Palette.Black.index,
    CharacterMappedCharacterSetHiresForeground01: Palette.Magenta.index,
    CharacterMappedCharacterSetMultiColourBackground00: Palette.Cyan.index,
    CharacterMappedCharacterSetMultiColourForeground01: Palette.Green.index,
    CharacterMappedCharacterSetMultiColourForeground10: Palette.Blue.index,
    CharacterMappedCharacterSetMultiColourForeground11: Palette.Red.index,
    CharacterMappedCharacterSetMode: 'HiRes',

    CharacterMappedScale: 1,
    CharacterMappedWidthChars: 40,

    PickingColour: ColourProperties.None,

    MarkedRegionsAppearance: MarkedRegionsAppearance.Dimmed
};

function setColour(state: GraphicsState, colourIndex: number, property: ColourProperties) {
    switch (property) {

        case ColourProperties.InterleavedHiresBackground00:
            state.InterleavedHiresBackground00 = colourIndex;
            break;
        case ColourProperties.InterleavedHiresForeground01:
            state.InterleavedHiresForeground01 = colourIndex;
            break;

        case ColourProperties.InterleavedMultiColourBackground00:
            state.InterleavedMultiColourBackground00 = colourIndex;
            break;
        case ColourProperties.InterleavedMultiColourForeground01:
            state.InterleavedMultiColourForeground01 = colourIndex;
            break;
        case ColourProperties.InterleavedMultiColourForeground10:
            state.InterleavedMultiColourForeground10 = colourIndex;
            break;
        case ColourProperties.InterleavedMultiColourForeground11:
            state.InterleavedMultiColourForeground11 = colourIndex;
            break;

        case ColourProperties.ContinuousHiresBackground00:
            state.ContinuousHiresBackground00 = colourIndex;
            break;
        case ColourProperties.ContinuousHiresForeground01:
            state.ContinuousHiresForeground01 = colourIndex;
            break;
        case ColourProperties.ContinuousMultiColourBackground00:
            state.ContinuousMultiColourBackground00 = colourIndex;
            break;
        case ColourProperties.ContinuousMultiColourForeground01:
            state.ContinuousMultiColourForeground01 = colourIndex;
            break;
        case ColourProperties.ContinuousMultiColourForeground10:
            state.ContinuousMultiColourForeground10 = colourIndex;
            break;
        case ColourProperties.ContinuousMultiColourForeground11:
            state.ContinuousMultiColourForeground11 = colourIndex;
            break;

        case ColourProperties.CharacterMappedHiresBackground00:
            state.CharacterMappedHiresBackground00 = colourIndex;
            break;
        case ColourProperties.CharacterMappedHiresForeground01:
            state.CharacterMappedHiresForeground01 = colourIndex;
            break;

        case ColourProperties.CharacterMappedMultiColourBackground00:
            state.CharacterMappedMultiColourBackground00 = colourIndex;
            break;
        case ColourProperties.CharacterMappedMultiColourForeground01:
            state.CharacterMappedMultiColourForeground01 = colourIndex;
            break;
        case ColourProperties.CharacterMappedMultiColourForeground10:
            state.CharacterMappedMultiColourForeground10 = colourIndex;
            break;
        case ColourProperties.CharacterMappedMultiColourForeground11:
            state.CharacterMappedMultiColourForeground11 = colourIndex;
            break;

        case ColourProperties.CharacterMappedExtendedColourBackground00:
            state.CharacterMappedExtendedColourBackground00 = colourIndex;
            break;
        case ColourProperties.CharacterMappedExtendedColourBackground01:
            state.CharacterMappedExtendedColourBackground01 = colourIndex;
            break;
        case ColourProperties.CharacterMappedExtendedColourBackground10:
            state.CharacterMappedExtendedColourBackground10 = colourIndex;
            break;
        case ColourProperties.CharacterMappedExtendedColourBackground11:
            state.CharacterMappedExtendedColourBackground11 = colourIndex;
            break;
        case ColourProperties.CharacterMappedExtendedColourForeground01:
            state.CharacterMappedExtendedColourForeground01 = colourIndex;
            break;

        case ColourProperties.CharacterMappedCharacterSetHiresBackground00:
            state.CharacterMappedCharacterSetHiresBackground00 = colourIndex;
            break;
        case ColourProperties.CharacterMappedCharacterSetHiresForeground01:
            state.CharacterMappedCharacterSetHiresForeground01 = colourIndex;
            break;
        case ColourProperties.CharacterMappedCharacterSetMultiColourBackground00:
            state.CharacterMappedCharacterSetMultiColourBackground00 = colourIndex;
            break;
        case ColourProperties.CharacterMappedCharacterSetMultiColourForeground01:
            state.CharacterMappedCharacterSetMultiColourForeground01 = colourIndex;
            break;
        case ColourProperties.CharacterMappedCharacterSetMultiColourForeground10:
            state.CharacterMappedCharacterSetMultiColourForeground10 = colourIndex;
            break;
        case ColourProperties.CharacterMappedCharacterSetMultiColourForeground11:
            state.CharacterMappedCharacterSetMultiColourForeground11 = colourIndex;
            break;

        default:
            break;
    }
}

const graphicsSlice = createSlice({
    name: 'graphics',
    initialState,
    reducers: {
        viewLayoutSet(state, action: PayloadAction<ViewLayout>) {
            logReducer('graphicsSlice', 'viewLayoutSet');
            state.ViewLayout = action.payload;
        },

        colourSet(state, action: PayloadAction<{ colourIndex: number, property: ColourProperties }>) {
            logReducer('graphicsSlice', 'colourSet');
            setColour(state, action.payload.colourIndex, action.payload.property);
        },

        pickingColourStarted(state, action: PayloadAction<ColourProperties>) {
            logReducer('graphicsSlice', 'pickingColourStarted');
            state.PickingColour = action.payload;
        },

        pickingColourFinished(state) {
            logReducer('graphicsSlice', 'pickingColourFinished');
            state.PickingColour = ColourProperties.None;
        },


        characterMappedModeSet(state, action: PayloadAction<ColourMode>) {
            logReducer('graphicsSlice', 'characterMappedModeSet');
            state.CharacterMappedMode = action.payload;
        },

        characterMappedCharacterSetModeSet(state, action: PayloadAction<ColourMode>) {
            logReducer('graphicsSlice', 'characterMappedCharacterSetModeSet');
            state.CharacterMappedCharacterSetMode = action.payload;
        },

        characterMappedCharacterSetAddressSet(state, action: PayloadAction<number>) {
            logReducer('graphicsSlice', 'characterMappedCharacterSetAddressSet');
            state.CharacterMappedCharacterSetAddress = action.payload;
        },

        characterMappedMainColourSourceSet(state, action: PayloadAction<ColourSource>) {
            logReducer('graphicsSlice', 'characterMappedMainColourSourceSet');
            state.CharacterMappedMainColourSource = action.payload;
        },

        characterMappedMainColourAddressCOLRAMSet(state, action: PayloadAction<number>) {
            logReducer('graphicsSlice', 'characterMappedMainColourAddressCOLRAMSet');
            state.CharacterMappedMainColourAddressCOLRAM = action.payload;
        },

        characterMappedMainColourAddressRAMSet(state, action: PayloadAction<number>) {
            logReducer('graphicsSlice', 'characterMappedMainColourAddressRAMSet');
            state.CharacterMappedMainColourAddressRAM = action.payload;
        },

        interleavedModeSet(state, action: PayloadAction<ColourMode>) {
            logReducer('graphicsSlice', 'interleavedModeSet');
            state.InterleavedMode = action.payload;
        },

        interleavedMainColourSourceSet(state, action: PayloadAction<ColourSource>) {
            logReducer('graphicsSlice', 'interleavedMainColourSourceSet');
            state.InterleavedMainColourSource = action.payload;
        },

        interleavedMainColourAddressRAMSet(state, action: PayloadAction<number>) {
            logReducer('graphicsSlice', 'interleavedMainColourAddressRAMSet');
            state.InterleavedMainColourAddressRAM = action.payload;
        },

        interleavedOtherColourSourceSet(state, action: PayloadAction<ColourSource>) {
            logReducer('graphicsSlice', 'interleavedOtherColourSourceSet');
            state.InterleavedOtherColourSource = action.payload;
        },

        interleavedOtherColourAddressCOLRAMSet(state, action: PayloadAction<number>) {
            logReducer('graphicsSlice', 'interleavedOtherColourAddressCOLRAMSet');
            state.InterleavedOtherColourAddressCOLRAM = action.payload;
        },

        interleavedOtherColourAddressRAMSet(state, action: PayloadAction<number>) {
            logReducer('graphicsSlice', 'interleavedOtherColourAddressRAMSet');
            state.InterleavedOtherColourAddressRAM = action.payload;
        },

        continuousModeSet(state, action: PayloadAction<ColourMode>) {
            logReducer('graphicsSlice', 'continuousModeSet');
            state.ContinuousMode = action.payload;
        },

        markedRegionsAppearanceSet(state, action: PayloadAction<MarkedRegionsAppearance>) {
            logReducer('graphicsSlice', 'markedRegionsAppearanceSet');
            state.MarkedRegionsAppearance = action.payload;
        },

        scaleSet(state, action: PayloadAction<{ layout: ViewLayout, scale: number }>) {
            logReducer('graphicsSlice', 'scaleSet');
            const { layout, scale } = action.payload;
            if (layout === 'CharacterMapped') {
                state.CharacterMappedScale = scale;
            } else if (layout === 'Interleaved') {
                state.InterleavedScale = scale;
            } else if (layout === 'Continuous') {
                state.ContinuousScale = scale;
            }
        },

        selectionWidthSet(state, action: PayloadAction<{ width: number, layout: ViewLayout }>) {
            logReducer('graphicsSlice', 'selectionWidthSet');
            const { width, layout } = action.payload;
            if (layout === 'CharacterMapped') {
                state.CharacterMappedWidthChars = width;
            } else if (layout === 'Interleaved') {
                state.InterleavedWidthChars = width;
            } else if (layout === 'Continuous') {
                state.ContinuousWidthChars = width;
            }
        },

        allSettingsUpdatedFromCommand(state, action: PayloadAction<GraphicCommand>) {
            logReducer('graphicsSlice', 'commandSet');
            const command = action.payload;
            const { layout, mode, mainSource, otherSource } = graphicsTypeToComponents(command.graphicsType ?? 'CharacterMappedHiresFixed');

            const defaultColour = Palette.Black.index;

            state.ViewLayout = layout;

            if (layout === 'CharacterMapped') {
                state.CharacterMappedMode = mode;
                state.CharacterMappedScale = command.scale;
                state.CharacterMappedWidthChars = widthChars(command);
                if (mode === 'HiRes') {
                    state.CharacterMappedHiresBackground00 = command.colour00 ?? defaultColour;
                    state.CharacterMappedHiresForeground01 = command.colour01 ?? defaultColour;
                } else if (mode === 'MultiColour') {
                    state.CharacterMappedMultiColourBackground00 = command.colour00 ?? defaultColour;
                    state.CharacterMappedMultiColourForeground01 = command.colour01 ?? defaultColour;
                    state.CharacterMappedMultiColourForeground10 = command.colour10 ?? defaultColour;
                    state.CharacterMappedMultiColourForeground11 = command.colour11 ?? defaultColour;
                } else if (mode === 'Extended') {
                    state.CharacterMappedExtendedColourBackground00 = command.colour00 ?? defaultColour;
                    state.CharacterMappedExtendedColourBackground01 = command.colour01 ?? defaultColour;
                    state.CharacterMappedExtendedColourBackground10 = command.colour10 ?? defaultColour;
                    state.CharacterMappedExtendedColourBackground11 = command.colour11 ?? defaultColour;
                    state.CharacterMappedExtendedColourForeground01 = command.extraColour ?? defaultColour;
                }

                state.CharacterMappedMainColourSource = mainSource;
                if (mainSource === 'MainRAM') {
                    state.CharacterMappedMainColourAddressRAM = command.referenceAddress2 ?? 0x0000;
                } else if (mainSource === 'ColourRAM') {
                    state.CharacterMappedMainColourAddressCOLRAM = command.referenceAddress2 ?? 0x0000;
                }

                state.CharacterMappedCharacterSetAddress = command.referenceAddress1 ?? 0x0000;

            } else if (layout === 'Interleaved') {
                state.InterleavedMode = mode;
                state.InterleavedScale = command.scale;
                state.InterleavedWidthChars = widthChars(command);

                if (mode === 'HiRes') {
                    state.InterleavedHiresBackground00 = command.colour00 ?? defaultColour;
                    state.InterleavedHiresForeground01 = command.colour01 ?? defaultColour;
                } else if (mode === 'MultiColour') {
                    state.InterleavedMultiColourBackground00 = command.colour00 ?? defaultColour;
                    state.InterleavedMultiColourForeground01 = command.colour01 ?? defaultColour;
                    state.InterleavedMultiColourForeground10 = command.colour10 ?? defaultColour;
                    state.InterleavedMultiColourForeground11 = command.colour11 ?? defaultColour;
                }

                state.InterleavedMainColourSource = mainSource;
                if (mainSource === 'MainRAM') {
                    state.InterleavedMainColourAddressRAM = command.referenceAddress1 ?? 0x0000;
                }

                state.InterleavedOtherColourSource = otherSource ?? 'ColourRAM';
                if (otherSource === 'MainRAM') {
                    state.InterleavedOtherColourAddressRAM = command.referenceAddress2 ?? 0x0000;
                } else if (otherSource === 'ColourRAM') {
                    state.InterleavedOtherColourAddressCOLRAM = command.referenceAddress2 ?? 0x0000;
                }

            } else if (layout === 'Continuous') {
                state.ContinuousMode = mode;
                state.ContinuousScale = command.scale;
                state.ContinuousWidthChars = widthChars(command);

                if (mode === 'HiRes') {
                    state.ContinuousHiresBackground00 = command.colour00 ?? defaultColour;
                    state.ContinuousHiresForeground01 = command.colour01 ?? defaultColour;
                } else if (mode === 'MultiColour') {
                    state.ContinuousMultiColourBackground00 = command.colour00 ?? defaultColour;
                    state.ContinuousMultiColourForeground01 = command.colour01 ?? defaultColour;
                    state.ContinuousMultiColourForeground10 = command.colour10 ?? defaultColour;
                    state.ContinuousMultiColourForeground11 = command.colour11 ?? defaultColour;
                }
            }
        }
    }
})

export const { characterMappedCharacterSetAddressSet, characterMappedCharacterSetModeSet, characterMappedMainColourAddressCOLRAMSet, characterMappedMainColourAddressRAMSet, characterMappedMainColourSourceSet, characterMappedModeSet, colourSet, allSettingsUpdatedFromCommand, continuousModeSet, interleavedMainColourAddressRAMSet, interleavedMainColourSourceSet, interleavedModeSet, interleavedOtherColourAddressCOLRAMSet, interleavedOtherColourAddressRAMSet, interleavedOtherColourSourceSet, markedRegionsAppearanceSet, pickingColourFinished, pickingColourStarted, scaleSet, selectionWidthSet, viewLayoutSet } = graphicsSlice.actions;
export default graphicsSlice.reducer;

export const selectViewLayout = (state: RootState) => state.graphics.ViewLayout;

export const selectPickingColour = (state: RootState) => state.graphics.PickingColour;

export const selectCharacterSetInfo = createSelector(
    (state: RootState) => state.graphics.ViewLayout,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetAddress,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetMode,
    (viewLayout, address, mode) => {
        logSelector('graphicsSlice', 'selectCharacterSetInfo');
        return {
            viewLayout,
            address,
            mode
        }
    }
)

export const selectSelectionWidth = createSelector(
    (state: RootState) => state.graphics.ViewLayout,
    (state: RootState) => state.graphics.CharacterMappedWidthChars,
    (state: RootState) => state.graphics.ContinuousWidthChars,
    (state: RootState) => state.graphics.InterleavedWidthChars,
    (layout, charMappedWidth, continuousWidth, interleavedWidth) => {
        logSelector('graphicsSlice', 'selectSelectionWidth');

        const selectionWidth = (layout === 'CharacterMapped') ? charMappedWidth : (
            (layout === 'Interleaved') ? interleavedWidth : continuousWidth
        )

        return { selectionWidth, layout };
    }
)


export const previewCommandSelector = createSelector(
    (state: RootState) => state.graphics,
    selectSelection,
    (gs, ts) => {
        logSelector('graphicsSlice', 'selectPreviewCommand');
        return newGraphicCommandFromState(gs, ts.selectionAddress, ts.selectionCount);
    }
)



export const selectDimMarked = createSelector(
    (state: RootState) => state.graphics.MarkedRegionsAppearance,
    state => {
        logSelector('graphicsSlice', 'selectDimMarked');
        return state === MarkedRegionsAppearance.Dimmed;
    }
)

export const selectContinuousScale = (state: RootState) => state.graphics.ContinuousScale;

export const selectCharacterMappedScale = (state: RootState) => state.graphics.CharacterMappedScale;

export const selectInterleavedScale = (state: RootState) => state.graphics.InterleavedScale;

export const selectCharacterSetData = createSelector(
    (state: RootState) => state.graphics.CharacterMappedCharacterSetMode,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetHiresBackground00,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetMultiColourBackground00,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetHiresForeground01,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetMultiColourForeground01,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetMultiColourForeground10,
    (state: RootState) => state.graphics.CharacterMappedCharacterSetMultiColourForeground11,
    (mode, h00, m00, h01, m01, m10, m11) => {
        logSelector('graphicsSlice', 'selectCharacterSetData');
        const isHires = mode === 'HiRes';
        return {
            type: isHires ? 'InterleavedHiresFixed' as GraphicsTypes : 'InterleavedMultiColourFixedFixed' as GraphicsTypes,
            colour00: isHires ? h00 : m00,
            colour01: isHires ? h01 : m01,
            colour10: isHires ? Palette.Black.index : m10,
            colour11: isHires ? Palette.Black.index : m11
        }
    }
)

export const unpackColourProperty = (property: ColourProperties, settings: GraphicsState) => {
    if (property === ColourProperties.InterleavedHiresBackground00) return { colourIndex: settings.InterleavedHiresBackground00, label: '0' };
    else if (property === ColourProperties.InterleavedHiresForeground01) return { colourIndex: settings.InterleavedHiresForeground01, label: '1' };

    else if (property === ColourProperties.InterleavedMultiColourBackground00) return { colourIndex: settings.InterleavedMultiColourBackground00, label: '00' };
    else if (property === ColourProperties.InterleavedMultiColourForeground01) return { colourIndex: settings.InterleavedMultiColourForeground01, label: '01' };
    else if (property === ColourProperties.InterleavedMultiColourForeground10) return { colourIndex: settings.InterleavedMultiColourForeground10, label: '10' };
    else if (property === ColourProperties.InterleavedMultiColourForeground11) return { colourIndex: settings.InterleavedMultiColourForeground11, label: '11' };

    else if (property === ColourProperties.ContinuousHiresBackground00) return { colourIndex: settings.ContinuousHiresBackground00, label: '0' };
    else if (property === ColourProperties.ContinuousHiresForeground01) return { colourIndex: settings.ContinuousHiresForeground01, label: '1' };
    else if (property === ColourProperties.ContinuousMultiColourBackground00) return { colourIndex: settings.ContinuousMultiColourBackground00, label: '00' };
    else if (property === ColourProperties.ContinuousMultiColourForeground01) return { colourIndex: settings.ContinuousMultiColourForeground01, label: '01' };
    else if (property === ColourProperties.ContinuousMultiColourForeground10) return { colourIndex: settings.ContinuousMultiColourForeground10, label: '10' };
    else if (property === ColourProperties.ContinuousMultiColourForeground11) return { colourIndex: settings.ContinuousMultiColourForeground11, label: '11' };

    else if (property === ColourProperties.CharacterMappedHiresBackground00) return { colourIndex: settings.CharacterMappedHiresBackground00, label: '0' };
    else if (property === ColourProperties.CharacterMappedHiresForeground01) return { colourIndex: settings.CharacterMappedHiresForeground01, label: '0' };

    else if (property === ColourProperties.CharacterMappedMultiColourBackground00) return { colourIndex: settings.CharacterMappedMultiColourBackground00, label: '00' };
    else if (property === ColourProperties.CharacterMappedMultiColourForeground01) return { colourIndex: settings.CharacterMappedMultiColourForeground01, label: '01' };
    else if (property === ColourProperties.CharacterMappedMultiColourForeground10) return { colourIndex: settings.CharacterMappedMultiColourForeground10, label: '10' };
    else if (property === ColourProperties.CharacterMappedMultiColourForeground11) return { colourIndex: settings.CharacterMappedMultiColourForeground11, label: '11' };

    else if (property === ColourProperties.CharacterMappedExtendedColourBackground00) return { colourIndex: settings.CharacterMappedExtendedColourBackground00, label: '00' };
    else if (property === ColourProperties.CharacterMappedExtendedColourBackground01) return { colourIndex: settings.CharacterMappedExtendedColourBackground01, label: '01' };
    else if (property === ColourProperties.CharacterMappedExtendedColourBackground10) return { colourIndex: settings.CharacterMappedExtendedColourBackground10, label: '10' };
    else if (property === ColourProperties.CharacterMappedExtendedColourBackground11) return { colourIndex: settings.CharacterMappedExtendedColourBackground11, label: '11' };
    else if (property === ColourProperties.CharacterMappedExtendedColourForeground01) return { colourIndex: settings.CharacterMappedExtendedColourForeground01, label: '1' };

    else if (property === ColourProperties.CharacterMappedCharacterSetHiresBackground00) return { colourIndex: settings.CharacterMappedCharacterSetHiresBackground00, label: '0' };
    else if (property === ColourProperties.CharacterMappedCharacterSetHiresForeground01) return { colourIndex: settings.CharacterMappedCharacterSetHiresForeground01, label: '1' };
    else if (property === ColourProperties.CharacterMappedCharacterSetMultiColourBackground00) return { colourIndex: settings.CharacterMappedCharacterSetMultiColourBackground00, label: '00' };
    else if (property === ColourProperties.CharacterMappedCharacterSetMultiColourForeground01) return { colourIndex: settings.CharacterMappedCharacterSetMultiColourForeground01, label: '01' };
    else if (property === ColourProperties.CharacterMappedCharacterSetMultiColourForeground10) return { colourIndex: settings.CharacterMappedCharacterSetMultiColourForeground10, label: '10' };
    else if (property === ColourProperties.CharacterMappedCharacterSetMultiColourForeground11) return { colourIndex: settings.CharacterMappedCharacterSetMultiColourForeground11, label: '11' };
    else return { colourIndex: 0, label: '-' };
}