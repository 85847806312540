import styles from './SavePanel.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAsync } from '../classes/LocalStorageHelper';
import { saveAsync as saveCloudAsync } from '../classes/CloudStorageHelper';
import { faCloud, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { newProjectDescription, serialise } from "../classes/ProjectDescriptionHelpers";
import { logError, logMajorComponentRender } from "../classes/Logger";
import { dirtySet } from "../store/projectSlice";
import { Stack } from './Stack';
import { cx, Utils } from '../classes/Utils';
import { logProjectActionAnalytic } from '../classes/code/Firebase';

export const SavePanel: React.FC = () => {

    logMajorComponentRender(SavePanel.name);

    const dispatch = useAppDispatch();
    const project = useAppSelector((state: RootState) => state.project);

    const handleOnExport = () => {
        const desc = serialise(newProjectDescription(project.name, project.guid, project.RAM, project.COLRAM, project.commands), Date.now());
        logProjectActionAnalytic('export_project', project.guid, project.name);
        Utils.downloadText(desc, encodeURIComponent(project.name))
    }

    const handleOnSave = async () => {

        logProjectActionAnalytic('save_project', project.guid, project.name);
        try {
            const t = Date.now();
            const saveMethod = project.isCloud ? saveCloudAsync : saveAsync;
            await saveMethod(newProjectDescription(project.name, project.guid, project.RAM, project.COLRAM, project.commands), t);
        } catch (error) {
            logError(String(error));
        }

        dispatch(dirtySet(false));
    }

    const saveButtonDisabled = (!project.dirty) || (project.name === '');
    const symbol = project.isCloud ? faCloud : faFloppyDisk;



    return (
        <Stack layout='horizontal'>
            <button onClick={handleOnExport} className={cx(styles.button, styles.smallButton)}>Export</button>
            <button onClick={handleOnSave} disabled={saveButtonDisabled} className={styles.button}><FontAwesomeIcon icon={symbol} className={styles.icon} /> Save</button>
        </Stack>
    )
}