import React from 'react';
import { selectActiveTool, ToolTypes } from '../store/toolSlice';

import styles from './TabsPanel.module.css';
import { logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';
import { cx } from '../classes/Utils';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { makeRoute } from '../App';
import { selectActiveMemoryView } from '../store/codeSlice';


export const TabsPanel: React.FunctionComponent = (props) => {

    logMajorComponentRender(TabsPanel.name);

    return (
        <Stack layout='horizontal' alignItems='center' justifyContent='start'>
            <Tab name='Graphics' tool='graphics' />
            <Tab name='Code' tool='code' />
            <Tab name='Library' tool='library' />
        </Stack>
    );
}

const Tab: React.FC<{ name: string, tool: ToolTypes }> = (props) => {

    const activeTool = useAppSelector(selectActiveTool);
    const memoryView = useAppSelector(selectActiveMemoryView);
    const { guid } = useParams();

    const { name, tool } = props;

    logMajorComponentRender(TabsPanel.name, `with tool ${tool}`);

    const selectedLink = <div className={cx(styles.tab, styles.selected)}>{name}</div>;
    const unselectedLink = <Link to={makeRoute({ guid, tool, memoryView })} className={cx(styles.unselected, styles.tab)}>{name}</Link>

    return (activeTool === tool) ? selectedLink : unselectedLink;

}