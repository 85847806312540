import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer } from "../classes/Logger";

export enum ViewportLocation {
    "Settings",
    "Main",
    "Preview",
    "MemoryMap"
}

export interface Viewport {
    location: ViewportLocation;
    show: { marked: boolean, selected: boolean };
    left: number;
    top: number;
    width: number;
    height: number;
}

export type RendererState = {
    glAvailable: boolean,
    viewports: Viewport[]
}

const initialState: RendererState = {
    glAvailable: false,
    viewports: []
}

const rendererSlice = createSlice({
    name: 'renderer',
    initialState,
    reducers: {

        glAvailableSet(state, action: PayloadAction<boolean>) {
            logReducer('rendererSlice', 'glAvailableSet');
            state.glAvailable = action.payload;
        },

        viewportSet(state, action: PayloadAction<Viewport>) {
            const newViewport = action.payload;

            const existingViewport = state.viewports.find(v => v.location === newViewport.location);
            if (existingViewport == null) {
                logReducer('rendererSlice', 'viewportSet', `Adding new viewport in location '${ViewportLocation[newViewport.location]}'`)
                state.viewports.push(newViewport);
            } else {
                if (existingViewport.height !== newViewport.height ||
                    existingViewport.width !== newViewport.width ||
                    existingViewport.left !== newViewport.left ||
                    existingViewport.top !== newViewport.top ||
                    existingViewport.show !== newViewport.show
                ) {
                    logReducer('rendererSlice', 'viewportSet', `Updating viewport in location '${ViewportLocation[newViewport.location]} : (${existingViewport.left},${existingViewport.top},${existingViewport.width},${existingViewport.height}) -> (${newViewport.left},${newViewport.top},${newViewport.width},${newViewport.height})'`)
                    existingViewport.height = newViewport.height;
                    existingViewport.width = newViewport.width;
                    existingViewport.left = newViewport.left;
                    existingViewport.top = newViewport.top;
                    existingViewport.show = newViewport.show;
                }
            }

        }
    }
})

export const { glAvailableSet, viewportSet } = rendererSlice.actions;
export default rendererSlice.reducer;

export const selectViewports = (state: RootState) => state.renderer.viewports;