import React from 'react';
import { SettingsRow } from './SettingsRow';
import { countAddedToAddress, selectionAddressSet, selectionCountSet, countTakenFromAddress, selectSelection, selectActiveTool } from '../../store/toolSlice';
import { HexNumberInput } from './HexNumberInput';
import { Utils } from '../../classes/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { selectMarkedUpMemory } from '../../store/projectSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import styles from '../SettingsPanel.module.css';
import { navigate, selectActiveMemoryView } from '../../store/codeSlice';


export const SelectionSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(SelectionSettingsSection.name);

    const { selectionAddress: selectionAddressRAM, selectionCount: selectionCountRAM, selectionAddressROM, selectionCountROM } = useAppSelector(selectSelection);

    const activeTool = useAppSelector(selectActiveTool);

    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const { ram, rom } = useAppSelector(selectMarkedUpMemory);
    const dispatch = useAppDispatch();

    const isRAM = activeTool !== 'code' || (activeMemoryView === 'ram');
    const memory = isRAM ? ram : rom;
    const selectionAddress = isRAM ? selectionAddressRAM : selectionAddressROM;
    const selectionCount = isRAM ? selectionCountRAM : selectionCountROM;

    const disableSkipCopyButtons = (activeTool === 'library') || !isRAM;
    const disableNavigateButton = (activeTool === 'library');

    const handleNavigate = () => {
        navigate({ address: selectionAddress, type: activeMemoryView });
    }

    const handleCopy = () => {

        const values = memory
            .slice(selectionAddress, selectionAddress + selectionCount)
            .map(m => `0x${Utils.to2DigitHexString(m.Value)}`);

        const perLine = 16;
        const numLines = Math.ceil(values.length / perLine);
        const lines: string[] = [];
        for (let index = 0; index < numLines; index++) {
            const startIndex = index * perLine;
            const endIndexFullLine = (index + 1) * perLine - 1;
            const endIndex = Math.min(endIndexFullLine, values.length - 1);
            const thisLine = values.slice(startIndex, endIndex + 1).join(", ");
            lines.push(thisLine);
        }

        const bytes = lines.join(",\n\t\t");

        const json = `const bytes = {\n\t"address": 0x${Utils.to4DigitHexString(selectionAddress)},\n\t"bytes": [\n\t\t${bytes}\n\t]\n};`;

        navigator.clipboard.writeText(json);
    }

    return (
        <SettingsRow label="Address">
            <HexNumberInput
                enabled={true}
                label="address"
                number={selectionAddress}
                onChange={v => dispatch(selectionAddressSet({ address: v, type: activeMemoryView }))}
                forceFourDigits={true}
            />

            <span>+</span>

            <HexNumberInput
                enabled={true}
                label="count"
                number={selectionCount}
                unit="bytes"
                onChange={v => dispatch(selectionCountSet({ count: v, type: activeMemoryView }))}
                forceFourDigits={false}
            />

            <button onClick={handleNavigate} disabled={disableNavigateButton}><FontAwesomeIcon icon={faCaretRight} className={styles.iconButton} /></button>
            <button onClick={handleCopy} disabled={disableSkipCopyButtons}><FontAwesomeIcon icon={faCopy} className={styles.iconButton} /></button>
            <button onClick={() => dispatch(countTakenFromAddress())} disabled={disableSkipCopyButtons}><FontAwesomeIcon icon={faAnglesLeft} className={styles.iconButton} /></button>
            <button onClick={() => dispatch(countAddedToAddress())} disabled={disableSkipCopyButtons}><FontAwesomeIcon icon={faAnglesRight} className={styles.iconButton} /></button>
        </SettingsRow>
    );
}