import React, { ChangeEvent, useEffect, useState } from 'react';
import { fromSnapshot as epFromSnapshot } from '../../classes/commands/EntryPointHelpers';
import { deserialise as deserialiseProjectDescription, newProjectDescription } from '../../classes/ProjectDescriptionHelpers';
import { SnapshotLoader } from '../../classes/SnapshotLoader';
import { PrepareCommands } from '../../classes/TestData';

import styles from './FilePicker.module.css';
import { ProjectInfo } from '../../classes/ProjectInfo';
import { deleteAsync, getAsync, listAsync as listLocalAsync, saveAsync } from '../../classes/LocalStorageHelper';
import { deleteAsync as deleteCloudAsync, getAsync as getCloudAsync, listAsync as listCloudAsync, saveAsync as saveCloudAsync } from '../../classes/CloudStorageHelper';
import { newGraphicCommandsFromSnapshot } from '../../classes/commands/GraphicCommandHelpers';
import { makeCopy, makeEmpty, makeGuid } from '../../classes/ProjectDescription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCloud, faCopy, faFloppyDisk, faFolderOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getAuth } from 'firebase/auth';
import { logError, logMajorComponentRender } from '../../classes/Logger';
import { bubbleBobbleBytes, creaturesBytes, montezumasRevengeBytes } from '../../classes/SnapshotExamples';
import { useNavigate } from 'react-router-dom';
import { logProjectActionAnalytic } from '../../classes/code/Firebase';

interface IFilePickerProps {
    onClose: () => void;
}

export const FilePicker: React.FC<IFilePickerProps> = (props) => {

    logMajorComponentRender(FilePicker.name);

    const navigate = useNavigate();

    const [isReady, setIsReady] = useState<boolean>(true);
    const [fileInfos, SetFileInfos] = useState<ProjectInfo[]>([]);
    const [fileInfosCloud, SetFileInfosCloud] = useState<ProjectInfo[]>([]);

    const fileInputRef = React.createRef<HTMLInputElement>();

    const refresh = async () => {

        try {
            const cloudFileInfos = await listCloudAsync();
            if (cloudFileInfos != null) {
                cloudFileInfos.sort((a, b) => b.timestamp - a.timestamp);
                SetFileInfosCloud(cloudFileInfos);
            }

            const localFileInfos = await listLocalAsync();
            localFileInfos.sort((a, b) => b.timestamp - a.timestamp);
            SetFileInfos(localFileInfos);
        } catch (error) {
            logError(String(error));
        }
    };

    useEffect(() => {
        (async () => {
            setIsReady(false);
            await refresh();
            setIsReady(true);
        })();
    }, []);

    const handleOpen = async (guid: string, name: string) => {

        logProjectActionAnalytic('open_project', guid, name);

        navigate(`/project/${guid}`);

        props.onClose();
    }

    const handleDelete = async (guid: string, name: string, isCloud: boolean) => {

        logProjectActionAnalytic('delete_project', guid, name);

        setIsReady(false);

        try {
            const deleteMethod = isCloud ? deleteCloudAsync : deleteAsync;
            await deleteMethod(guid);
            await refresh();
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
    }

    const handleMakeACopy = async (guid: string, name: string, isCloud: boolean, toOtherTarget: boolean = false) => {

        const action = !toOtherTarget ? 'duplicate_project' : (isCloud ? 'duplicate_cloud_project_to_local' : 'duplicate_local_project_to_cloud');
        logProjectActionAnalytic(action, guid, name);

        setIsReady(false);

        try {
            const getMethod = isCloud ? getCloudAsync : getAsync;
            const file = await getMethod(guid);
            if (file != null) {
                const copy = makeCopy(file);

                const targetIsCloud = (isCloud !== toOtherTarget);
                const saveMethod = targetIsCloud ? saveCloudAsync : saveAsync;
                await saveMethod(copy, Date.now());

                await refresh();
            }
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
    }

    const forwardClickToInputElement = () => {
        fileInputRef.current!.click();
    };

    const handleNew = async () => {

        setIsReady(false);

        try {
            const desc = makeEmpty();

            logProjectActionAnalytic('new_project', desc.guid, desc.name);

            await saveAsync(desc, Date.now());

            navigate(`/project/${desc.guid}`);
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
        props.onClose();
    }

    const handleCreateTestProject = async () => {
        setIsReady(false);

        try {
            const { commands, RAM, COLRAM } = PrepareCommands();
            const desc = newProjectDescription('TEST DATA', makeGuid(), RAM, COLRAM, commands);
            await saveAsync(desc, Date.now());

            navigate(`/project/${desc.guid}`);
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
        props.onClose();
    }

    const handleCancel = () => {
        props.onClose();
    }

    const handleAddFromFile = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.files == null) { return; }
        if (e.target.files.length === 0) { return; }

        setIsReady(false);

        try {
            const file = e.target.files[0];

            const reader = new FileReader();

            const isProject = file.name.endsWith(".46c");
            const isSnapshot = file.name.endsWith(".vsf");
            if (isProject) {
                reader.onload = (event: ProgressEvent<FileReader>) => addFromProjectFile(event.target?.result as string, file.name);
                reader.readAsText(file);
            } else if (isSnapshot) {
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    const contents = new Uint8Array(event.target?.result as ArrayBuffer);
                    addFromSnapshotFile(contents, file.name)
                };
                reader.readAsArrayBuffer(file);
            }
        }
        catch (error) {
            setIsReady(true);

            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }
    }

    const addFromProjectFile = async (contents: string, name: string) => {

        logProjectActionAnalytic('new_project_from_project_file', '00000000', name);

        const desc = deserialiseProjectDescription(contents);
        if (desc == null) {
            setIsReady(true);
            return;
        }

        desc.guid = makeGuid();

        try {
            await saveAsync(desc, Date.now());

            navigate(`/project/${desc.guid}`);
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
        props.onClose();
    }

    const addFromSnapshotFile = async (data: Uint8Array, name: string) => {

        logProjectActionAnalytic('new_project_from_snapshot_file', '00000000', name);

        const snapshot = new SnapshotLoader().LoadFromBytes(data);
        if (snapshot == null) {
            setIsReady(true);
            return;
        }

        const { RAM, COLRAM, graphics, cpu } = snapshot;
        const graphicCommands = newGraphicCommandsFromSnapshot(graphics);
        const entryPointCommand = epFromSnapshot(cpu);
        const desc = newProjectDescription(name, makeGuid(), Array.from(RAM), Array.from(COLRAM), [...graphicCommands, entryPointCommand]);

        try {
            await saveAsync(desc, Date.now());

            navigate(`/project/${desc.guid}`);
        }
        catch (error) {
            if (!(error instanceof Error)) { throw error }

            logError(`${error.name} : ${error.message}`);
        }

        setIsReady(true);
        props.onClose();
    }


    const getRow = (p: ProjectInfo, index: number) => {

        const timestamp = new Date(p.timestamp);
        const count = isNaN(p.commandCount) ? '-' : p.commandCount;
        const isCloud = p.location === 'cloud';
        const symbol = isCloud ? faCloud : faFloppyDisk;
        const otherSymbol = isCloud ? faFloppyDisk : faCloud;

        const isSignedIn = (getAuth().currentUser != null);

        return (
            <tr key={index} className={styles.itemRow}>
                <td className={styles.itemDetails}>
                    <FontAwesomeIcon icon={symbol} />
                    <span className={styles.name}> &quot;{p.name}&quot;</span>
                    <br />
                    <span className={styles.details}>{count} cmds, saved {timestamp.toLocaleString()}</span>
                </td>
                <td className={styles.itemOperations}>
                    <button onClick={() => handleOpen(p.guid, p.name)}><FontAwesomeIcon icon={faFolderOpen} /></button>
                    <span className={styles.spacer}>/</span>
                    <button onClick={() => handleMakeACopy(p.guid, p.name, isCloud)}><FontAwesomeIcon icon={faCopy} /></button>
                    <span className={styles.spacer}>/</span>
                    {isSignedIn && <>
                        <button onClick={() => handleMakeACopy(p.guid, p.name, isCloud, true)}><FontAwesomeIcon icon={faCopy} /> <FontAwesomeIcon icon={faArrowRight} /> <FontAwesomeIcon icon={otherSymbol} /></button>
                        <span className={styles.spacer}>/</span>
                    </>}
                    <button onClick={() => handleDelete(p.guid, p.name, isCloud)}><FontAwesomeIcon icon={faTrash} /></button>
                </td>
            </tr>
        );
    }

    const getControls = () => {

        return (
            <>
                <tr key={'controls'} className={styles.itemRow}>
                    <td className={styles.itemDetails}>
                        <button onClick={handleCreateTestProject}>Load test</button>
                    </td>
                    <td className={styles.itemOperations}>
                        <button onClick={handleCancel}>Cancel</button>
                        <span className={styles.spacer}>/</span>
                        <button onClick={forwardClickToInputElement}>Add from file and open...</button>
                        <input className={styles.hidden} ref={fileInputRef} type="file" onChange={handleAddFromFile}></input>
                        <span className={styles.spacer}>/</span>
                        <button onClick={handleNew}>New</button>
                    </td>
                </tr>
                <tr>
                    <td className={styles.itemDetails}>
                        Examples :
                    </td>
                    <td className={styles.itemDetails}>
                        <button onClick={() => addFromSnapshotFile(bubbleBobbleBytes, 'BubbleBobble')}>BubbleBobble</button>
                        <button onClick={() => addFromSnapshotFile(creaturesBytes, 'Creatures')}>Creatures</button>
                        <button onClick={() => addFromSnapshotFile(montezumasRevengeBytes, "Montezuma's Revenge")}>Montezuma's Revenge</button>
                    </td>
                </tr>
            </>
        );
    }

    const getItems = (items: ProjectInfo[]) => {
        return items.map((item, index) => getRow(item, index));
    }

    return (
        <>
            {!isReady && <div className={styles.overlay} />}
            <h4 className={styles.section}>Cloud</h4>
            <table className={styles.list}><tbody>{getItems(fileInfosCloud)}</tbody></table>
            <h4 className={styles.section}>Local storage</h4>
            <table className={styles.list}><tbody>{getItems(fileInfos)}</tbody></table>
            <table className={styles.list}><tbody>{getControls()}</tbody></table>
        </>
    );
}